@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.slideUp {
  animation-name: slideUp;
  animation-duration: 0.3s;
}

@keyframes slideUp {
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideDown {
  animation-name: slideDown;
  animation-duration: 0.3s;
}

@keyframes slideDown {
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideLeft {
  animation-name: slideLeft;
  animation-duration: 0.3s;
}

@keyframes slideLeft {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideRight {
  animation-name: slideRight;
  animation-duration: 0.3s;
}

@keyframes slideRight {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rollIn {
  0% {
    transform: translateX(calc(100% + 1rem));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rollOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(100% + 1rem));
  }
}

@keyframes rollInLink1 {
  0% {
    transform: translateX(calc(-100% * 1));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rollInLink2 {
  0% {
    transform: translateX(calc(-100% * 2));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rollInLink3 {
  0% {
    transform: translateX(calc(-100% * 3));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rollInLink4 {
  0% {
    transform: translateX(calc(-100% * 4));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rollOutLink1 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% * 1));
  }
}

@keyframes rollOutLink2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% * 2));
  }
}

@keyframes rollOutLink3 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% * 3));
  }
}

@keyframes rollOutLink4 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% * 4));
  }
}

@keyframes notify {
  0% {
    max-height: 50rem;
    transform: translate3d(calc(100% + 0.8rem), 0, 0);
    margin-bottom: 0.8rem;
  }

  5% {
    max-height: 50rem;
    transform: translate3d(calc(100% + 0.8rem), 0, 0);
    margin-bottom: 0.8rem;
  }

  7% {
    max-height: 50rem;
    transform: translate3d(0, 0, 0);
    margin-bottom: 0.8rem;
  }

  92% {
    max-height: 50rem;
    transform: translate3d(0, 0, 0);
    margin-bottom: 0.8rem;
  }

  99% {
    max-height: 0;
    transform: translate3d(0, 0, 0);
    margin-bottom: 0.8rem;
  }

  100% {
    max-height: 0;
    transform: translate3d(0, 0, 0);
    margin-bottom: 0;
  }
}

@keyframes zeroHeightToAuto {
  from {
    height: 0;
  }

  to {
    height: auto;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes progress {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rippleButton {
  0% {
    width: 0;
  }

  50% {
    width: 100%;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}
