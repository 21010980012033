/** Reset **/
@use 'abstracts/variables' as vars; 

dp-day-calendar .dp-calendar-nav-container {
  height: auto !important;
}

dp-day-calendar .dp-nav-header {
  position: unset !important;
  transform: none !important;
  display: block !important;
}

dp-day-calendar .dp-nav-btns-container {
  transform: none !important;
}

dp-day-calendar button.dp-calendar-nav-left,
dp-day-calendar button.dp-calendar-nav-right {
  background: transparent !important;
}

dp-day-calendar .dp-calendar-wrapper,
dp-day-calendar .dp-calendar-weekday {
  border: none !important;
}

/** Style **/
dp-day-calendar .dp-nav-btns-container {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  right: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;

  .dp-calendar-nav-container-right {
    margin-left: auto !important;
    margin-right: 1rem !important;
  }

  .dp-calendar-nav-container-left {
    margin-left: 1rem;
  }
}

dp-day-calendar .dp-day-calendar-container {
  width: 30rem !important;
  height: 30rem !important;
  border-radius: 0.7rem !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
}

dp-day-calendar .dp-calendar-nav-container {
  padding: 1rem 4rem !important;
  text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5) !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  background-color: vars.$teal-200 !important;
  color: vars.$white !important;
  font-weight: bold !important;
  position: relative !important;
  border: 0 !important;
}

dp-day-calendar .dp-nav-header {
  font-size: 1.6rem !important;
}

dp-day-calendar .dp-calendar-weekday {
  height: 3.5rem !important;
  width: 3.5rem !important;
  margin-bottom: 0.5rem !important;
  padding: 0.5rem !important;
  font-weight: bold !important;
}

dp-day-calendar .dp-calendar-day {
  background: transparent !important;
  height: 3.5rem !important;
  width: 3.5rem !important;

  &.dp-selected {
    background-color: vars.$teal-200 !important;
    color: vars.$white !important;
    border-radius: 50% !important;
  }

  &:disabled {
    opacity: 0.35 !important;
  }
}

dp-date-picker {
  position: absolute !important;
}

dp-day-calendar .dp-calendar-wrapper {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

dp-day-calendar .dp-day-calendar-container .dp-calendar-nav-container .dp-nav-header span,
dp-day-calendar .dp-day-calendar-container .dp-calendar-wrapper .dp-weekdays span.dp-calendar-weekday {
  text-transform: capitalize;
}
