@use 'abstracts/variables' as vars;

.overlay-more-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-no-dark-backdrop {
  background-color: rgba(0, 0, 0, 0);
}

.mdc-dialog--close {
  display: none !important;
}

.overlay-banned-country-backdrop {
  background-color: vars.$grey-200;
}

// TODO: Add height 100%
body.mobile,
body.native {
  .full-screen-modal .mat-dialog-container {
    width: 100%;
  }
}

body.desktop {
  .full-screen-modal {
    transform: translate(-50%, -50%);
  }
}

.betradioPanel {
  align-self: stretch;
}

.modal-padding {
  padding: 1rem;
}

// Age Restriction
.u-dialog1500 {
  // full non transparent background color to give the effect that this is the only open dialog
  background: vars.$grey-blue-500;
  z-index: vars.$zIndex-1500;

  + div {
    z-index: vars.$zIndex-1500;
  }
}

body.desktop {
  .betsDatepicker {
    right: 2rem !important;
  }
}

body .searchSelectbox_autocompleteContainer.mat-mdc-autocomplete-panel {
  box-shadow: 0 0.1rem 0.4rem rgba(vars.$black, 0.25);
  background-color: vars.$white;
  border-radius: 0.4rem;
  padding: 0;

  & mat-option {
    background-color: vars.$white;
    color: vars.$grey-blue-400;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.8rem;
    padding: 1.2rem 1.6rem;

    &[aria-selected='true'].mdc-list-item--selected {
      background-color: vars.$white;

      & span {
        color: vars.$grey-blue-400;
      }
    }
  }
}
