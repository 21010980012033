@use 'abstracts/variables' as vars;
@use 'abstracts/mixins' as mixins;

.nvInput {
  position: relative;
  display: flex;
  width: 100%;

  &.rounded {
    input {
      border-radius: 0.4rem;
    }
  }

  input {
    width: 100%;
  }

  &.sizeSmall {
    input {
      font-size: 1.1rem;
      padding: 1rem 0.8rem;
      height: 3.5rem;
    }
  }

  &.sizeMedium {
    input {
      font-size: 1.3rem;
      padding: 1.3rem 1rem;
      height: 4.5rem;
    }
  }

  &.sizeBig {
    input {
      font-size: 1.5rem;
      padding: 1.5rem 1.2rem;
      height: 5.5rem;
    }
  }

  &.statusBasic {
    input {
      background-color: vars.$grey-200;
      border: 0.1rem solid vars.$grey-400;

      &:-webkit-autofill {
        box-shadow: 0 0 0 3rem vars.$grey-200 inset !important;
      }
    }
  }

  &.statusSuccess {
    &:not(.typePassword) {
      @include mixins.icon-check();

      &::before {
        position: absolute;
        right: 1.3rem;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 1em;
        height: 1em;
        color: vars.$green-300;
        z-index: vars.$zIndex-base;
      }
    }

    input {
      background-color: vars.$green-200;
      border: 0.1rem solid vars.$green-300;

      &:-webkit-autofill {
        box-shadow: 0 0 0 3rem vars.$green-200 inset !important;
      }
    }
  }

  &.statusError {
    input {
      background-color: vars.$red-200;
      border: 0.1rem solid vars.$red-300;

      &:-webkit-autofill {
        box-shadow: 0 0 0 3rem vars.$red-200 inset !important;
      }
    }
  }

  &.statusFocus {
    input {
      background-color: vars.$white;
      border: 0.1rem solid vars.$blue-200;

      &:-webkit-autofill {
        box-shadow: 0 0 0 3rem vars.$white inset !important;
      }
    }
  }

  &.statusCompleted {
    input {
      background-color: vars.$grey-200;
      border: 0.1rem solid vars.$grey-500;

      &:-webkit-autofill {
        box-shadow: 0 0 0 3rem vars.$grey-200 inset !important;
      }
    }
  }

  &.statusDisabled input,
  input:disabled {
    background-color: vars.$grey-200;
    border: 0.1rem solid vars.$grey-400;
    opacity: 0.35;

    &:-webkit-autofill {
      box-shadow: 0 0 0 3rem vars.$grey-200 inset !important;
    }
  }

  .icon-hide,
  .icon-show {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 4rem;
    height: 100%;
    right: 0;
    top: 0;
    font-size: 1.3rem;
    color: vars.$grey-500;
  }
}

cm-input {
  .input_form {
    &.removeArrows {
      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
