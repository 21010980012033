@use 'abstracts/variables' as vars;
@use 'abstracts/mixins' as mixins;

.splash {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: vars.$grey-blue-500;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 20vh 0;

  &_logo {
    margin: 0 auto 4rem;

    @include mixins.getMainIconByHeight('bg-novibet_white', 40);
  }
}
