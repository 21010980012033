@use 'abstracts/variables' as vars ;
@use 'sass:color';

.nvButton {
  position: relative;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  cursor: pointer;

  .loaderContainer {
    font-size: 0.6em;
  }

  &_content {
    position: relative;
    z-index: vars.$zIndex-base;
  }

  &.rounded {
    border-radius: 0.4rem;
  }

  &.sizeSmall {
    font-size: 1rem;

    .nvButton_content {
      padding: 0.5rem;
    }
  }

  &.sizeMedium {
    font-size: 1.2rem;

    .nvButton_content {
      padding: 0.85rem;
    }
  }

  &.sizeLarge {
    font-size: 1.4rem;

    .nvButton_content {
      padding: 1rem;
    }
  }

  &.sizeXLarge {
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.8rem;

    .nvButton_content {
      padding: 1.5rem;
    }
  }

  &.themeFillRed {
    background-color: rgba(vars.$red-400, 0.85);
    color: vars.$white;

    .loaderContainer,
    &::after {
      background-color: vars.$red-400;
    }
  }

  &.themeFillWhite {
    background-color: vars.$white;
    color: vars.$grey-600;

    .loaderContainer,
    &::after {
      background-color: vars.$grey-200;
    }
  }

  &.themeFillTealDark {
    background-color: vars.$grey-blue-200;
    color: vars.$teal-50;

    .loaderContainer,
    &::after {
      background-color: vars.$grey-blue-300;
    }
  }

  &.themeFillDark {
    background-color: vars.$grey-blue-200;
    color: vars.$white;

    .loaderContainer,
    &::after {
      background-color: vars.$grey-blue-300;
    }
  }

  &.themeFillTeal {
    background-color: vars.$teal-200;
    color: vars.$white;

    .loaderContainer,
    &::after {
      background-color: color.adjust(vars.$teal-200, $lightness: -2%);
    }
  }

  &.themeBorder {
    background-color: vars.$white;
    color: vars.$grey-600;
    border: 0.1rem solid vars.$grey-400;

    .loaderContainer,
    &::after {
      background-color: vars.$grey-200;
    }

    .loader {
      div {
        border-color: vars.$grey-600 transparent transparent;
      }
    }
  }

  &.statusDisabled {
    opacity: 0.7;
  }

  .loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: calc(#{vars.$zIndex-base} * 2);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10%;
    transform: scale(0, 0);
    transform-origin: 50% 50%;
    margin: auto;
  }

  &:focus:not(:active)::after {
    animation: ripple 1s ease-out;
  }

  @keyframes ripple {
    0% {
      opacity: 1;
      transform: scale(0, 1);
    }

    25% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(2, 2);
    }

    100% {
      opacity: 0;
      transform: scale(2, 2);
    }
  }
}
