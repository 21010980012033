// pallete colors

$white: #ffffff;
$black: #000000;
$white-50: #d9d9d9;
$white-100: #e2e2e2;

// grey
$grey_1: rgba($white, 0.6);
$grey-10: #0d0d0d;
$grey_20: #202735;
$grey_30: #141b24;
$grey_40: #434343;
$grey_50: #454545;
$grey_60: #232323;
$grey-100: #f8f8f8;
$grey-200: #f2f2f2;
$grey-250: #f3f5f6;
$grey-300: #e1e1e1;
$grey-305: rgba(13, 56, 89, 0.05);
$grey-310: rgba(13, 56, 89, 0.1);
$grey-320: rgba(13, 56, 89, 0.25);
$grey-350: #e7ecef;
$grey-400: #bdbdbd;
$grey-450: #9a9ea4;
$grey-500: #828282;
$grey-600: #333333;
$grey-700: rgba($white, 0.08);
$grey-800: rgba($white, 0.15);
$grey-900: rgba($white, 0.6);

// grey-blue
$grey-blue-100: #acb0b9; // 172, 176, 185
$grey-blue-200: #404756;
$grey-blue-300: #303540;
$grey-blue-400: #202735; // 32, 39, 53
$grey-blue-500: #0a1324; // 10, 19, 36
$grey-blue-550: #090b0f;
$grey-blue-600: #3d464f;
$grey-blue-700: #171c26;
$grey-blue-800: #050911;
$grey-blue-850: #072033;
$grey-blue-900: #151d2c;
$grey-blue-1000: #151e31;

// blue
$blue-100: #eaf5ff;
$blue-200: #3399ff; // 51, 153, 255
$blue-300: #6df6ff; // 109, 246, 255
$blue-400: #0d3859; // 13, 56, 89

// green
$green-100: #f4fcf5;
$green-200: #e9f9ec;
$green-300: #2ac940;
$green-400: #16957a;
$green-500: #1f7685;
$green-600: #3cb878;
$green-700: #4bff52;

// teal
$teal-50: #a6e9f0;
$teal-60: #92babb;
$teal-100: #29a8ac; // 41, 168, 172
$teal-200: #008f93; // 0, 143, 147
$teal-300: #28a8ac;
$teal-400: #1dbecf;
$teal-500: #2abfc3;
$teal-550: #53a5aa;
$teal-600: #007e82; // 0, 126, 130
$teal-650: #006b6e;
$teal-700: #075052;
$teal-750: #00777a;
$teal-800: #154450;

// red
$red-100: #fff6f7;
$red-200: #ffedee;
$red-250: #d79696;
$red-300: #ff4b5a;
$red-400: #ee2547;
$red-500: #e2402f;
$red-600: #e54c2c;
$red-650: #f06424;
$red-700: #e5202f;
$red-800: #dd0000;

// yellow
$yellow-100: #fefaeb;
$yellow-150: #fdf1c9;
$yellow-200: #f3ca3e;
$yellow-300: #ab8313;
$yellow-400: #f3e13e;
$yellow-500: #faa51a;
$yellow-600: #fead42;

// pink
$pink-300: #ff729b;
$pink-400: #ff54a1;
$pink-500: #f60059;
$pink-600: #db004f;

// purple
$purple-300: #86026f;
$purple-350: #9e07ae;
$purple-400: #8c00a9;
$purple-500: #6a0158;
$purple-600: #6b29ac;
$purple-700: #8f00ff;
$purple-800: #8811ff; // casino_tag_jackpot

// orange
$orange-10: #f3743e;
$orange-20: #f3833e;

// novileague-current
$novileague-blue-100: #6df5ff;
$novileague-blue-200: #27e7ed;
$novileague-blue-300: #03010a;
$novileague-blue-400: #d3fcff;
$novileague-blue-500: #a0f9ff;
$novileague-yellow-100: #f2f200;
$novileague-brown-100: #f3953e;
$novileague-green-100: #c4f831;

// poker
$poker-blue-100: #0d76e1;
$poker-blue-200: #11b8bb;
$poker-blue-300: #0a4c51;
$poker-light-gray-100: #d7dffd;

// gradient
$sportsbook-gradient: radial-gradient(75% 125% at 100% 100%, #008f93 10%, rgba(0, 143, 147, 0) 100%), #151d2d;
$sportbook-ssp-home: rgba($teal-300, 0.7);
$sportbook-ssp-away: rgba($white, 0.7);
$poker-gradient: radial-gradient(75.95% 124.31% at 100% 100%, rgba($teal-200, 0.85) 10.17%, rgba($teal-200, 0) 100%),
  $grey-blue-500;
