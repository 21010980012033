@use 'abstracts/variables' as vars;

html {
  font-size: 62.5% !important;
}

body,
input,
button {
  font-family: 'Open Sans', sans-serif;
}

body {
  color: vars.theme-color(base-text-cl, base);

  &:not(.native) {
    background-color: vars.$grey-blue-500;
  }
}

span[class^='icon-']::before,
span[class*=' icon-']::before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
