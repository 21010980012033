
@use 'sass:math';

$mainIconsWidth: 67.8rem;
$mainIconsHeight: 63.2rem;

@mixin getMainIconSize($logo, $size, $width, $height, $type, $posX, $posY) {
  @if $type == 'width' {
    $scale: math.div($size, $width);

    width: $size * 0.1rem;
    min-width: $size * 0.1rem;
    height: calc(#{$height} * #{$scale} * 0.1rem);

    @include calculateMainIconBackground($logo,
      $size,
      $width,
      $height,
      $type,
      $posX,
      $posY,
      $scale);
  }

  @if $type == 'height' {
    $scale: math.div($size, $height);

    height: $size * 0.1rem;
    width: calc(#{$width} * #{$scale} * 0.1rem);
    min-width: calc(#{$width} * #{$scale} * 0.1rem);

    @include calculateMainIconBackground($logo,
      $size,
      $width,
      $height,
      $type,
      $posX,
      $posY,
      $scale);
  }

  @if $type == 'scale' {
    $scale: $size;

    height: $height * $size * 0.1rem;
    width: $width * $size * 0.1rem;
    min-width: $width * $size * 0.1rem;

    @include calculateMainIconBackground($logo,
      $size,
      $width,
      $height,
      $type,
      $posX,
      $posY,
      $scale);
  }
}

@mixin calculateMainIconBackground($logo,
  $size,
  $width,
  $height,
  $type,
  $posX,
  $posY,
  $scale) {
  background-image: url('/assets/images/sprites/sprite_main.1665748946777.png');
  background-size: calc(#{$mainIconsWidth} * #{$scale}) calc(#{$mainIconsHeight} * #{$scale});
  background-position: calc(#{$posX} * #{$scale} * 0.1rem) calc(#{$posY} * #{$scale} * 0.1rem);
}

@mixin getMainIcon($logo, $size, $type) {
  @if $logo == 'bg-novibet_white' {
    $width: 255;
    $height: 60;

    @include getMainIconSize($logo, $size, $width, $height, $type, 0, 0);
  }

  @if $logo == 'bg-active_month' {
    $width: 253;
    $height: 39;

    @include getMainIconSize($logo, $size, $width, $height, $type, 0, -60);
  }

  @if $logo == 'bg-inactive_month' {
    $width: 253;
    $height: 39;

    @include getMainIconSize($logo, $size, $width, $height, $type, 0, -99);
  }

  @if $logo == 'bg-novibet_gray' {
    $width: 211;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, 0, -138);
  }

  @if $logo == 'bg-no_results' {
    $width: 150;
    $height: 205;

    @include getMainIconSize($logo, $size, $width, $height, $type, 0, -188);
  }

  @if $logo == 'bg-noviladies' {
    $width: 200;
    $height: 75;

    @include getMainIconSize($logo, $size, $width, $height, $type, -255, 0);
  }

  @if $logo == 'bg-mga_malta' {
    $width: 182;
    $height: 37;

    @include getMainIconSize($logo, $size, $width, $height, $type, -255, -75);
  }

  @if $logo == 'bg-certificate' {
    $width: 142;
    $height: 142;

    @include getMainIconSize($logo, $size, $width, $height, $type, -255, -112);
  }

  @if $logo == 'bg-android_badget' {
    $width: 140;
    $height: 42;

    @include getMainIconSize($logo, $size, $width, $height, $type, -255, -254);
  }

  @if $logo == 'bg-app_store_badget' {
    $width: 140;
    $height: 41;

    @include getMainIconSize($logo, $size, $width, $height, $type, -255, -296);
  }

  @if $logo == 'bg-google_play_badget' {
    $width: 140;
    $height: 41;

    @include getMainIconSize($logo, $size, $width, $height, $type, -255, -337);
  }

  @if $logo == 'bg-huawei_badget' {
    $width: 140;
    $height: 41;

    @include getMainIconSize($logo, $size, $width, $height, $type, 0, -393);
  }

  @if $logo == 'bg-thawte' {
    $width: 122;
    $height: 31;

    @include getMainIconSize($logo, $size, $width, $height, $type, -140, -393);
  }

  @if $logo == 'bg-fingerprint' {
    $width: 105;
    $height: 114;

    @include getMainIconSize($logo, $size, $width, $height, $type, -150, -188);
  }

  @if $logo == 'bg-exclusive' {
    $width: 110;
    $height: 110;

    @include getMainIconSize($logo, $size, $width, $height, $type, -455, 0);
  }

  @if $logo == 'bg-one-hundred-ganiota' {
    $width: 102;
    $height: 14;

    @include getMainIconSize($logo, $size, $width, $height, $type, -455, -110);
  }

  @if $logo == 'bg-egr_award' {
    $width: 101;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -455, -124);
  }

  @if $logo == 'bg-egr_award_2021' {
    $width: 101;
    $height: 31;

    @include getMainIconSize($logo, $size, $width, $height, $type, -455, -154);
  }

  @if $logo == 'bg-sbc_award' {
    $width: 101;
    $height: 31;

    @include getMainIconSize($logo, $size, $width, $height, $type, -455, -185);
  }

  @if $logo == 'bg-casino_game_play' {
    $width: 100;
    $height: 100;

    @include getMainIconSize($logo, $size, $width, $height, $type, -455, -216);
  }

  @if $logo == 'bg-zero-ganiota' {
    $width: 83;
    $height: 14;

    @include getMainIconSize($logo, $size, $width, $height, $type, -455, -316);
  }

  @if $logo == 'bg-100_payout' {
    $width: 72;
    $height: 34;

    @include getMainIconSize($logo, $size, $width, $height, $type, -455, -330);
  }

  @if $logo == 'bg-sun_clouds' {
    $width: 67;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -455, -364);
  }

  @if $logo == 'bg-attention_red_big' {
    $width: 65;
    $height: 58;

    @include getMainIconSize($logo, $size, $width, $height, $type, -150, -302);
  }

  @if $logo == 'bg-gamstop_logo' {
    $width: 64;
    $height: 13;

    @include getMainIconSize($logo, $size, $width, $height, $type, -455, -414);
  }

  @if $logo == 'bg-casinomeister_logo' {
    $width: 62;
    $height: 15;

    @include getMainIconSize($logo, $size, $width, $height, $type, -255, -378);
  }

  @if $logo == 'bg-tag_100_no_text' {
    $width: 62;
    $height: 62;

    @include getMainIconSize($logo, $size, $width, $height, $type, 0, -434);
  }

  @if $logo == 'bg-askgamblers_logo' {
    $width: 60;
    $height: 15;

    @include getMainIconSize($logo, $size, $width, $height, $type, -317, -378);
  }

  @if $logo == 'bg-betslip_without' {
    $width: 60;
    $height: 60;

    @include getMainIconSize($logo, $size, $width, $height, $type, -62, -434);
  }

  @if $logo == 'bg-chat' {
    $width: 60;
    $height: 57;

    @include getMainIconSize($logo, $size, $width, $height, $type, -122, -434);
  }

  @if $logo == 'bg-eeep-el' {
    $width: 42;
    $height: 60;

    @include getMainIconSize($logo, $size, $width, $height, $type, -182, -434);
  }

  @if $logo == 'bg-eeep-en' {
    $width: 42;
    $height: 60;

    @include getMainIconSize($logo, $size, $width, $height, $type, -224, -434);
  }

  @if $logo == 'bg-gambling_commission_logo' {
    $width: 60;
    $height: 16;

    @include getMainIconSize($logo, $size, $width, $height, $type, -150, -360);
  }

  @if $logo == 'bg-noviladies_mini' {
    $width: 60;
    $height: 47;

    @include getMainIconSize($logo, $size, $width, $height, $type, -266, -434);
  }

  @if $logo == 'bg-rain' {
    $width: 60;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -326, -434);
  }

  @if $logo == 'bg-snow' {
    $width: 57;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -386, -434);
  }

  @if $logo == 'bg-super_odds' {
    $width: 57;
    $height: 36;

    @include getMainIconSize($logo, $size, $width, $height, $type, -262, -393);
  }

  @if $logo == 'bg-super_quote' {
    $width: 57;
    $height: 38;

    @include getMainIconSize($logo, $size, $width, $height, $type, -319, -393);
  }

  @if $logo == 'bg-chase' {
    $width: 56;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -443, -434);
  }

  @if $logo == 'bg-cycling_mountain_bike' {
    $width: 56;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -499, -434);
  }

  @if $logo == 'bg-flat' {
    $width: 56;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, 0, -496);
  }

  @if $logo == 'bg-hurdle' {
    $width: 56;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -56, -496);
  }

  @if $logo == 'bg-no_bets' {
    $width: 53;
    $height: 56;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, 0);
  }

  @if $logo == 'bg-be_protected' {
    $width: 54;
    $height: 54;

    @include getMainIconSize($logo, $size, $width, $height, $type, 0, -546);
  }

  @if $logo == 'bg-cycling_road' {
    $width: 54;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -112, -496);
  }

  @if $logo == 'bg-cycling_track' {
    $width: 54;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -166, -496);
  }

  @if $logo == 'bg-field_hockey' {
    $width: 54;
    $height: 47;

    @include getMainIconSize($logo, $size, $width, $height, $type, -220, -496);
  }

  @if $logo == 'bg-grammi_voitheias_logo' {
    $width: 53;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -56);
  }

  @if $logo == 'bg-helplines_logo' {
    $width: 53;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -76);
  }

  @if $logo == 'bg-athletics' {
    $width: 51;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -96);
  }

  @if $logo == 'bg-gymnastics' {
    $width: 50;
    $height: 51;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -146);
  }

  @if $logo == 'bg-badminton' {
    $width: 50;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -197);
  }

  @if $logo == 'bg-cycling_bmx' {
    $width: 42;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -247);
  }

  @if $logo == 'bg-olympic_games' {
    $width: 50;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -274, -496);
  }

  @if $logo == 'bg-sun' {
    $width: 50;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -324, -496);
  }

  @if $logo == 'bg-super_quote_promotion' {
    $width: 50;
    $height: 42;

    @include getMainIconSize($logo, $size, $width, $height, $type, -374, -496);
  }

  @if $logo == 'bg-supper_odds' {
    $width: 50;
    $height: 42;

    @include getMainIconSize($logo, $size, $width, $height, $type, -424, -496);
  }

  @if $logo == 'bg-swimming' {
    $width: 50;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -474, -496);
  }

  @if $logo == 'bg-weightlifting' {
    $width: 50;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -54, -546);
  }

  @if $logo == 'bg-wrestling' {
    $width: 50;
    $height: 50;

    @include getMainIconSize($logo, $size, $width, $height, $type, -104, -546);
  }

  @if $logo == 'bg-zero_odds_0' {
    $width: 50;
    $height: 42;

    @include getMainIconSize($logo, $size, $width, $height, $type, -154, -546);
  }

  @if $logo == 'bg-zero_odds_100' {
    $width: 50;
    $height: 42;

    @include getMainIconSize($logo, $size, $width, $height, $type, -204, -546);
  }

  @if $logo == 'bg-default-shirt-light' {
    $width: 48;
    $height: 36;

    @include getMainIconSize($logo, $size, $width, $height, $type, -376, -393);
  }

  @if $logo == 'bg-giropay_logo' {
    $width: 48;
    $height: 22;

    @include getMainIconSize($logo, $size, $width, $height, $type, -254, -546);
  }

  @if $logo == 'bg-kethea_logo' {
    $width: 46;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -302, -546);
  }

  @if $logo == 'bg-course_type' {
    $width: 45;
    $height: 40;

    @include getMainIconSize($logo, $size, $width, $height, $type, -348, -546);
  }

  @if $logo == 'bg-start' {
    $width: 45;
    $height: 45;

    @include getMainIconSize($logo, $size, $width, $height, $type, -393, -546);
  }

  @if $logo == 'bg-stop' {
    $width: 45;
    $height: 45;

    @include getMainIconSize($logo, $size, $width, $height, $type, -438, -546);
  }

  @if $logo == 'bg-ecogra_logo' {
    $width: 44;
    $height: 21;

    @include getMainIconSize($logo, $size, $width, $height, $type, -211, -138);
  }

  @if $logo == 'bg-super_quote_match' {
    $width: 44;
    $height: 21;

    @include getMainIconSize($logo, $size, $width, $height, $type, -211, -159);
  }

  @if $logo == 'bg-supper_odds_tag' {
    $width: 44;
    $height: 21;

    @include getMainIconSize($logo, $size, $width, $height, $type, -483, -546);
  }

  @if $logo == 'bg-zero_odds_100_tag' {
    $width: 44;
    $height: 21;

    @include getMainIconSize($logo, $size, $width, $height, $type, -527, -546);
  }

  @if $logo == 'bg-bojoko_logo' {
    $width: 43;
    $height: 15;

    @include getMainIconSize($logo, $size, $width, $height, $type, -150, -376);
  }

  @if $logo == 'bg-problem_gambling_support_logo' {
    $width: 42;
    $height: 19;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -297);
  }

  @if $logo == 'bg-super_quote_crown' {
    $width: 38;
    $height: 42;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -316);
  }

  @if $logo == 'bg-formula_1' {
    $width: 40;
    $height: 32;

    @include getMainIconSize($logo, $size, $width, $height, $type, -397, -112);
  }

  @if $logo == 'bg-ibas_logo' {
    $width: 40;
    $height: 19;

    @include getMainIconSize($logo, $size, $width, $height, $type, -397, -144);
  }

  @if $logo == 'bg-horse_race' {
    $width: 39;
    $height: 35;

    @include getMainIconSize($logo, $size, $width, $height, $type, -397, -163);
  }

  @if $logo == 'bg-mga_logo' {
    $width: 39;
    $height: 17;

    @include getMainIconSize($logo, $size, $width, $height, $type, -397, -198);
  }

  @if $logo == 'bg-live-icon' {
    $width: 38;
    $height: 12;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -358);
  }

  @if $logo == 'bg-table_tennis' {
    $width: 38;
    $height: 31;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -370);
  }

  @if $logo == 'bg-water_polo' {
    $width: 30;
    $height: 38;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -401);
  }

  @if $logo == 'bg-world-cup-logo' {
    $width: 36;
    $height: 38;

    @include getMainIconSize($logo, $size, $width, $height, $type, -397, -215);
  }

  @if $logo == 'bg-cycling' {
    $width: 37;
    $height: 32;

    @include getMainIconSize($logo, $size, $width, $height, $type, -215, -302);
  }

  @if $logo == 'bg-super_bikes' {
    $width: 37;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -215, -334);
  }

  @if $logo == 'bg-a_z' {
    $width: 36;
    $height: 17;

    @include getMainIconSize($logo, $size, $width, $height, $type, -397, -253);
  }

  @if $logo == 'bg-zero_odds_0_tag' {
    $width: 36;
    $height: 21;

    @include getMainIconSize($logo, $size, $width, $height, $type, -397, -270);
  }

  @if $logo == 'bg-0_ganiota' {
    $width: 35;
    $height: 34;

    @include getMainIconSize($logo, $size, $width, $height, $type, -397, -291);
  }

  @if $logo == 'bg-live_now' {
    $width: 24;
    $height: 35;

    @include getMainIconSize($logo, $size, $width, $height, $type, -565, -439);
  }

  @if $logo == 'bg-moto_gp' {
    $width: 35;
    $height: 28;

    @include getMainIconSize($logo, $size, $width, $height, $type, -397, -325);
  }

  @if $logo == 'bg-ice_hockey' {
    $width: 32;
    $height: 34;

    @include getMainIconSize($logo, $size, $width, $height, $type, -397, -353);
  }

  @if $logo == 'bg-live_scores' {
    $width: 34;
    $height: 22;

    @include getMainIconSize($logo, $size, $width, $height, $type, -215, -364);
  }

  @if $logo == 'bg-n_gray' {
    $width: 34;
    $height: 34;

    @include getMainIconSize($logo, $size, $width, $height, $type, -524, -496);
  }

  @if $logo == 'bg-n_white' {
    $width: 34;
    $height: 34;

    @include getMainIconSize($logo, $size, $width, $height, $type, -571, -546);
  }

  @if $logo == 'bg-play' {
    $width: 34;
    $height: 34;

    @include getMainIconSize($logo, $size, $width, $height, $type, -302, -566);
  }

  @if $logo == 'bg-wrc' {
    $width: 34;
    $height: 29;

    @include getMainIconSize($logo, $size, $width, $height, $type, -527, -567);
  }

  @if $logo == 'bg-boxing' {
    $width: 30;
    $height: 33;

    @include getMainIconSize($logo, $size, $width, $height, $type, -424, -393);
  }

  @if $logo == 'bg-golf' {
    $width: 32;
    $height: 33;

    @include getMainIconSize($logo, $size, $width, $height, $type, -483, -567);
  }

  @if $logo == 'bg-politics' {
    $width: 30;
    $height: 33;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, 0);
  }

  @if $logo == 'bg-casino_tag_mobile' {
    $width: 32;
    $height: 32;

    @include getMainIconSize($logo, $size, $width, $height, $type, -254, -568);
  }

  @if $logo == 'bg-darts' {
    $width: 32;
    $height: 32;

    @include getMainIconSize($logo, $size, $width, $height, $type, 0, -600);
  }

  @if $logo == 'bg-fifa' {
    $width: 32;
    $height: 22;

    @include getMainIconSize($logo, $size, $width, $height, $type, -32, -600);
  }

  @if $logo == 'bg-futsal' {
    $width: 32;
    $height: 26;

    @include getMainIconSize($logo, $size, $width, $height, $type, -64, -600);
  }

  @if $logo == 'bg-statistics' {
    $width: 32;
    $height: 25;

    @include getMainIconSize($logo, $size, $width, $height, $type, -96, -600);
  }

  @if $logo == 'bg-100' {
    $width: 31;
    $height: 17;

    @include getMainIconSize($logo, $size, $width, $height, $type, -571, -580);
  }

  @if $logo == 'bg-blog' {
    $width: 30;
    $height: 31;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -33);
  }

  @if $logo == 'bg-counter_strike' {
    $width: 30;
    $height: 31;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -64);
  }

  @if $logo == 'bg-eurovision' {
    $width: 30;
    $height: 31;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -95);
  }

  @if $logo == 'bg-league_of_legends' {
    $width: 28;
    $height: 31;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -126);
  }

  @if $logo == 'bg-american_football' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -128, -600);
  }

  @if $logo == 'bg-baseball' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -158, -600);
  }

  @if $logo == 'bg-basketball' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -188, -600);
  }

  @if $logo == 'bg-beachvolley' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -218, -600);
  }

  @if $logo == 'bg-chess' {
    $width: 30;
    $height: 28;

    @include getMainIconSize($logo, $size, $width, $height, $type, -248, -600);
  }

  @if $logo == 'bg-cricket' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -278, -600);
  }

  @if $logo == 'bg-dota' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -308, -600);
  }

  @if $logo == 'bg-entertainment' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -338, -600);
  }

  @if $logo == 'bg-e_sports' {
    $width: 30;
    $height: 28;

    @include getMainIconSize($logo, $size, $width, $height, $type, -368, -600);
  }

  @if $logo == 'bg-football' {
    $width: 30;
    $height: 29;

    @include getMainIconSize($logo, $size, $width, $height, $type, -398, -600);
  }

  @if $logo == 'bg-football_cup' {
    $width: 30;
    $height: 29;

    @include getMainIconSize($logo, $size, $width, $height, $type, -428, -600);
  }

  @if $logo == 'bg-generic-possession-ball' {
    $width: 30;
    $height: 27;

    @include getMainIconSize($logo, $size, $width, $height, $type, -458, -600);
  }

  @if $logo == 'bg-generic' {
    $width: 30;
    $height: 26;

    @include getMainIconSize($logo, $size, $width, $height, $type, -488, -600);
  }

  @if $logo == 'bg-generic_blue' {
    $width: 30;
    $height: 26;

    @include getMainIconSize($logo, $size, $width, $height, $type, -518, -600);
  }

  @if $logo == 'bg-handball' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -548, -600);
  }

  @if $logo == 'bg-martial_arts' {
    $width: 30;
    $height: 25;

    @include getMainIconSize($logo, $size, $width, $height, $type, -578, -600);
  }

  @if $logo == 'bg-popular' {
    $width: 30;
    $height: 29;

    @include getMainIconSize($logo, $size, $width, $height, $type, -608, -600);
  }

  @if $logo == 'bg-rugby_league' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, 0);
  }

  @if $logo == 'bg-rugby_union' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -30);
  }

  @if $logo == 'bg-snooker' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -60);
  }

  @if $logo == 'bg-tennis' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -90);
  }

  @if $logo == 'bg-virtuals_dark' {
    $width: 30;
    $height: 28;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -120);
  }

  @if $logo == 'bg-virtuals_white' {
    $width: 30;
    $height: 28;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -148);
  }

  @if $logo == 'bg-volley' {
    $width: 30;
    $height: 30;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -176);
  }

  @if $logo == 'bg-verify_email_gray' {
    $width: 29;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -206);
  }

  @if $logo == 'bg-verify_email_green' {
    $width: 29;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -226);
  }

  @if $logo == 'bg-gaelic_football' {
    $width: 26;
    $height: 24;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -246);
  }

  @if $logo == 'bg-gaelic_hurling' {
    $width: 26;
    $height: 24;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -270);
  }

  @if $logo == 'bg-verify_mobile_gray' {
    $width: 24;
    $height: 26;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -294);
  }

  @if $logo == 'bg-verify_mobile_green' {
    $width: 24;
    $height: 26;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -320);
  }

  @if $logo == 'bg-kyc-warning-icon' {
    $width: 25;
    $height: 25;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -157);
  }

  @if $logo == 'bg-rg_icon' {
    $width: 25;
    $height: 24;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -182);
  }

  @if $logo == 'bg-weights_black' {
    $width: 23;
    $height: 25;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -346);
  }

  @if $logo == 'bg-info_blue' {
    $width: 24;
    $height: 24;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -206);
  }

  @if $logo == 'bg-novis' {
    $width: 23;
    $height: 19;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -371);
  }

  @if $logo == 'bg-18+_logo' {
    $width: 20;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -390);
  }

  @if $logo == 'bg-21+_logo' {
    $width: 20;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -410);
  }

  @if $logo == 'bg-bulgaria' {
    $width: 20;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -430);
  }

  @if $logo == 'bg-calendar' {
    $width: 18;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -450);
  }

  @if $logo == 'bg-finland' {
    $width: 20;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -230);
  }

  @if $logo == 'bg-germany' {
    $width: 20;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -250);
  }

  @if $logo == 'bg-greece' {
    $width: 20;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -270);
  }

  @if $logo == 'bg-live_streaming_icon' {
    $width: 20;
    $height: 17;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -290);
  }

  @if $logo == 'bg-mrgamble_logo' {
    $width: 20;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -307);
  }

  @if $logo == 'bg-norway' {
    $width: 20;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -327);
  }

  @if $logo == 'bg-sweden' {
    $width: 20;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -347);
  }

  @if $logo == 'bg-uk' {
    $width: 20;
    $height: 20;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -367);
  }

  @if $logo == 'bg-live' {
    $width: 19;
    $height: 10;

    @include getMainIconSize($logo, $size, $width, $height, $type, -618, -387);
  }

  @if $logo == 'bg-sidebar-icon' {
    $width: 18;
    $height: 15;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -470);
  }

  @if $logo == 'bg-error' {
    $width: 17;
    $height: 17;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -485);
  }

  @if $logo == 'bg-promo' {
    $width: 15;
    $height: 13;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -502);
  }

  @if $logo == 'bg-spin' {
    $width: 15;
    $height: 15;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -515);
  }

  @if $logo == 'bg-net_position_coin' {
    $width: 14;
    $height: 11;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -530);
  }

  @if $logo == 'bg-ireland' {
    $width: 13;
    $height: 13;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -541);
  }

  @if $logo == 'bg-mexico' {
    $width: 13;
    $height: 13;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -554);
  }

  @if $logo == 'bg-us' {
    $width: 13;
    $height: 13;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -567);
  }

  @if $logo == 'bg-vector' {
    $width: 13;
    $height: 12;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -580);
  }

  @if $logo == 'bg-info-fill' {
    $width: 10;
    $height: 10;

    @include getMainIconSize($logo, $size, $width, $height, $type, -648, -592);
  }

}
