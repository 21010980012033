@mixin text_ellipsis() {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin no_text_ellipsis() {
  overflow: visible;
  -ms-text-overflow: clip;
  -o-text-overflow: clip;
  text-overflow: clip;
  white-space: normal;
}
