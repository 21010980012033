@use 'abstracts/variables' as vars;

:root {
  --market-bet-item-backgound-color: #{vars.$white};
  --market-bet-item-backgound-color-hover: linear-gradient(0deg, rgba(41, 168, 172, .16), rgba(41, 168, 172, .16)), #ffffff;
  --market-bet-item-caption-color: #{vars.$grey-blue-400};
  --market-bet-item-price-color: #{vars.$teal-100};
  --market-bet-item-lock-color: #{vars.$blue-300};
  --market-bet-item-direction: row;
  --market-bet-item-opacity: 1;
  --market-bet-item-content-position: space-between;
}