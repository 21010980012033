@font-face {
  font-display: swap;
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 600 800;
  src: url('/assets/fonts/open-sans-condensed-bold.ttf') format('truetype-variations');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/open-sans-condensed-regular.ttf') format('truetype-variations');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/open-sans-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/open-sans-italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/open-sans-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/open-sans-500italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/open-sans-600.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/open-sans-600italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/open-sans-700.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/open-sans-700italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/open-sans-800.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('/assets/fonts/open-sans-800italic.woff2') format('woff2');
}
