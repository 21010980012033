@media screen and (min-width: 2560px) {
  html {
    font-size: 68.75% !important; // 11px
  }
}

@media screen and (min-width: 3840px) {
  html {
    font-size: 75% !important; // 12px
  }
}
