@use 'abstracts/mixins/utilities' as mixins;

:root {
  --header-height: 10.6rem;
  --sliding-header: 0;
  --burger-top: 1.8rem;
  --mobile-header-height: 6rem;
  --mobile-header-sticky-top: 0;
  --content-padding: 0.8rem;
  --content-gap: 0.8rem;
  --drawer-extra-offset-top: 0rem;
}

body.desktop {
  --content-padding: 1.6rem;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;

  &.no-scroll {
    overflow: hidden;
  }
}

button {
  outline: 0;
  box-shadow: none;
  user-select: none;
  border: 0;
  padding: 0;
  color: inherit;
  -webkit-tap-highlight-color: transparent;

  @include mixins.pointer();

  &:active,
  &:focus,
  &:visited {
    -webkit-tap-highlight-color: transparent;
  }
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  @include mixins.pointer();

  &:active,
  &:focus,
  &:visited {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}

select {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

*:focus {
  outline: none;
}

img[src=''],
img:not([src]) {
  opacity: 0;
}
