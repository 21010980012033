@use 'abstracts/variables' as vars;
@use 'abstracts/mixins' as mixins;

.u-cmp {
  @extend .u-flexColumn;

  width: 100%;
}

.u-flex {
  display: flex;
}

.u-flexRow {
  @extend .u-flex;

  flex-direction: row;
}

.u-flexColumn {
  @extend .u-flex;

  flex-direction: column;
}

.u-flexRowReverse {
  @extend .u-flex;

  flex-direction: row-reverse;
}

.u-flexColumnReverse {
  @extend .u-flex;

  flex-direction: column-reverse;
}

.u-flexWrap {
  flex-flow: wrap;
}

.u-flexXCenter {
  justify-content: center;
}

.u-flexYCenter {
  align-items: center;
}

.u-flexYEnd {
  align-items: end;
}

.u-flexXEnd {
  justify-content: end;
}

.u-flexCenter {
  @extend .u-flexXCenter;
  @extend .u-flexYCenter;
}

.u-flexSpaceBetween {
  justify-content: space-between;
}

.u-fullWidth {
  width: 100%;
}

.u-fullHeight {
  height: 100%;
}

.u-fullVhHeight {
  height: 100vh;
}

.u-textCenter {
  text-align: center;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-hidden {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  margin: 0;
  height: 0;
  width: 0;
}

.u-invisible {
  opacity: 0;
  overflow: hidden;
}

.u-clickable {
  min-width: 3.6rem;
  min-height: 3.6rem;
}

.u-seoHiddenLink {
  display: flex;
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.desktop .u-hoverOpacity5 {
  opacity: 0.5;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

.desktop .u-hoverOpacity6 {
  opacity: 0.6;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

.desktop .u-hoverOpacity7 {
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

.u-closeDialogBtn {
  justify-content: flex-end;
  padding: 1.6rem;
}

.u-gap-responsive {
  column-gap: 0.8rem;

  @media screen and (min-width: vars.$breakpoint-768) {
    column-gap: 1.6rem;
  }

  @media screen and (min-width: vars.$breakpoint-1200) {
    column-gap: 2rem;
  }

  @media screen and (min-width: vars.$breakpoint-1600) {
    column-gap: 2.4rem;
  }
}

.u-placeholderHeight {
  display: block;
  min-height: 0.1rem;
}

.u-text-ellipsis {
  @include mixins.text_ellipsis();
}

.u-pointer {
  @include mixins.pointer()
}
