@mixin reverseScale() {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

@mixin overflow($axis, $value: auto) {
  @if $axis == x {
    overflow-x: $value;
    overflow-y: hidden;
  } @else {
    overflow-y: $value;
    overflow-x: hidden;
  }

  transform: translate3d(0, 0, 0);
}

@mixin on-hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

@mixin pointer {
  @include on-hover() {
    cursor: pointer;
  }
}

@mixin line-clamp($numberOfLines, $maxHeight: '') {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $numberOfLines;
  line-clamp: $numberOfLines;
  overflow: hidden;
  display: -webkit-box;
  white-space: break-spaces;

  @if $maxHeight != '' {
    max-height: $maxHeight;
  }
}
