@use 'abstracts/variables' as vars;

.kumulos-prompt {
  background-color: vars.$white !important;
  color: vars.$grey-600 !important;
  left: auto !important;
  right: 0 !important;
  margin: 0.8rem 2.8rem 0.8rem !important;
  border-radius: 0.8rem !important;
  min-height: 12rem !important;
  box-shadow: 0 4px 6px 0 hsl(0deg 0% 0% / 32%) !important;
  padding: 0 !important;
  gap: 0.5rem 1rem !important;
  width: 41rem !important;
  font-family: Open Sans, sans-serif !important;
  display: grid !important;
  grid-template-columns: auto auto !important;
  grid-template-rows: auto auto auto !important;

  @media screen and (max-width: vars.$breakpoint-480-max) {
    width: auto !important;
    height: auto !important;
    left: 0.8rem !important;
    right: 0.8rem !important;
    top: 0.8rem !important;
  }

  .kumulos-alert-icon {
    border-radius: 0.4rem !important;
    border: 1.2rem solid vars.$grey_20 !important;
    width: 6.4rem !important;
    height: 6.4rem !important;
    grid-column: 1 !important;
    grid-row: 1 !important;
    margin: 1.2rem 0 0 1.2rem !important;
  }

  .kumulos-alert-content {
    display: contents !important;

    .kumulos-alert-header {
      grid-column: 2 !important;
      grid-row: 1 !important;
      margin: 1.2rem 1.2rem 0 0 !important;

      h1 {
        font-weight: 700 !important;
        font-size: 1.4rem !important;
        align-self: center !important;
      }
    }

    .kumulos-alert-body {
      font-size: 1.2rem !important;
      order: 3 !important;
      grid-row: 2 !important;
      grid-column: 1 / 3 !important;
      margin: 0 1.2rem !important;
    }
  }

  .kumulos-alert-actions {
    grid-row: 3 !important;
    grid-column: 1/3 !important;
    gap: 1rem !important;
    margin-top: 0 !important;
    padding: 1rem 0.8rem 0.8rem !important;
    height: auto !important;

    button {
      flex: 1 1 0 !important;
      margin: 0 !important;
      font-size: 1.2rem !important;
      text-transform: none !important;
      padding: 0.85rem !important;
    }

    .kumulos-action-button-cancel {
      font-weight: 400 !important;
      background: none !important;
      color: vars.$grey-blue-100 !important;
    }

    .kumulos-action-button-confirm {
      background-color: vars.$teal-200 !important;
      color: vars.$white !important;
      box-shadow: none !important;
      border-radius: 0.4rem !important;
    }
  }

}
