@use 'abstracts/variables' as vars;

.loader {
  display: inline-block;
  position: relative;
  width: 3.5em;
  height: 3.5em;

  &.relative {
    font-size: inherit;
  }
}

.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0.5em solid;
  border-radius: 50%;
  animation: rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: vars.$white transparent transparent;
}

.loader {
  &.accent {
    div {
      border-color: vars.$grey-600 transparent transparent;
    }
  }
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}
