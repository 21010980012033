@use '@angular/material' as mat;
@use 'abstracts/variables' as variables;
@use 'variables' as vars;
@include mat.datepicker-theme(vars.$my-theme);

mat-calendar {
  .mat-calendar-table-header {
    // #region Hide divider
    tr:last-child {
      display: none;
    }

    // #endregion
  }

  mat-month-view {
    .mat-calendar-body {
      tr:first-child {
        // #region Hide label
        .mat-calendar-body-label:first-child[colspan='7'] {
          display: none;
        }

        .mat-calendar-body-label:first-child:not([colspan='7']) {
          opacity: 0;
        }

        // #endregion
      }
    }
  }

  .mat-calendar-body {
    // #region Style selected/focus cell
    .mat-calendar-body-cell-content {
      border-radius: 0.2rem !important;

      &.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        border-color: variables.$teal-100;
      }
    }

    // #endregion
  }
}
