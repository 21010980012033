// spaces
$padding-main: 0.7rem;
$padding-marketview: 0.7rem;
$padding-event: 0.7rem;
$padding-eventBodyHeader: 1.3rem;
$padding-eventBodyDetails: 0.5rem;
$margin-event: 0.6rem;
$margin-section: 0.7rem;
$margin-button: 0.6rem;
$margin-casinoGame: 0.6rem;
$filter-button-padding: 0.7em;
$padding-casino: 0.7rem;
$casinoHomeGap: 0.8rem;
$casinoHomeRadius: 0.4rem;

// font-sizes
$marketview-header: 1.7rem;
$competition-header: 1.4rem;
$market-header: 1.5rem;
$event-header: 1.4rem;
$event-competitor: 1.4rem;
$bet-item: 1.3rem;
$daily-coupon-time: 1.2rem;
$daily-coupon-small-size: 1.3rem;
$daily-coupon-header: 1.7rem;
$daily-coupon-main-size: 1.6rem;
$daily-coupon-mid-size: 1.4rem;

// border-radius
$card-radius: 0.7rem;
$button-radius: 0.7rem;
$daily-coupon-filters-radius: 0.4rem;
$daily-coupon-filters-time-radius: 1.6rem;

// collapse-icon
$collapse-small: 0.9rem;
$collapse-medium: 1rem;
$collapse-primary: 1.2rem;

// casino-home-grid
$grid-gameMinSize: 90;
$grid-gameMaxSize: 145;
$grid-itemsOnScreen: 3.75;

// casino-category-grid
$grid-startingQuery: 408;
$grid-queryDistance: 131;
$grid-casinoColumns: 3;
$grid-liveColumns: 2;
$grid-limit: 1366;

// width
$sportsCarouselItemMaxWidth: 55rem;
$casinoCarouselItemMaxWidth: 58rem;
$casinoSkillzPageCarouselItemMaxWidth: 36.5rem;
$filter-button-size: 3.2rem;

// height
$header-height: 6rem;
$navigationBanners-height: 4.7rem;
$categories-height: 6.619rem;
$navbar-height: 4.4rem;
$bottomNavbar-height: 4.8rem;

// ratio
$sportsCarouselItemRatio: 3.063;
$casinoCarouselItemRatio: 1.97;
$casinoSkillzPageCarouselItemRatio: 2.7;

// z-index
$zIndex-negative: -1;
$zIndex-base: 1;
$zIndex-10: 10;
$zIndex-100: 100;
$zIndex-500: 500;
$zIndex-betslip: 501;
$zIndex-modal: 1000;

// z-index priority Dialogs
$zIndex-1100: 1100;
$zIndex-1200: 1200;
$zIndex-1300: 1300;
$zIndex-1400: 1400;
$zIndex-1500: 1500;

// betBuilderHistory
$betBuilderHistoryFixedHeight: 2.5rem * 0.5 + 0.6rem;
$betBuilderHistoryChainContainerSize: 1.6rem;

// sportsbook region image
$regionImageSize: 1.3rem;
$competitorImageSize: 2.4rem;
$competitorImageSizeBig: 2.8rem;
$marketViewGroupImageSize: 1.6rem;

// page min-height
$default-min-height: calc(100vh - #{$header-height});
$new-account-layout-min-height: calc(100vh - (#{$header-height} + 3.4rem));
$old-layout-min-height: calc(100vh - (#{$header-height} + #{$navbar-height}));
$small-min-height: calc(
  100vh - (#{$header-height} + #{$categories-height} + #{$navigationBanners-height} + #{$bottomNavbar-height})
);
$medium-min-height: calc(100vh - (#{$header-height} + #{$categories-height} + #{$bottomNavbar-height}));
$large-min-height: calc(100vh - (#{$header-height} + #{$bottomNavbar-height}));

// desktop

// height
$desktop-header-height: 13.8rem;
$desktop-sportsbook-header-height-fallback: 10.6rem;
$desktop-sportsbook-header-height: var(--header-height);
$desktop-min-height: calc(100vh - (#{$desktop-header-height} - 0.8rem));

// casino redesign breakpoints
$breakpoint-3840: 3840px;
$breakpoint-3840-max: calc(#{$breakpoint-3840} - 1px);
$breakpoint-2560: 2560px;
$breakpoint-2560-max: calc(#{$breakpoint-2560} - 1px);
$breakpoint-1920: 1920px;
$breakpoint-1920-max: calc(#{$breakpoint-1920} - 1px);
$breakpoint-1600: 1600px;
$breakpoint-1600-max: calc(#{$breakpoint-1600} - 1px);
$breakpoint-1200: 1200px;
$breakpoint-1200-max: calc(#{$breakpoint-1200} - 1px);
$breakpoint-1024: 1024px;
$breakpoint-1024-max: calc(#{$breakpoint-1024} - 1px);
$breakpoint-924: 924px;
$breakpoint-924-max: calc(#{$breakpoint-924} - 1px);
$breakpoint-768: 768px;
$breakpoint-768-max: calc(#{$breakpoint-768} - 1px);
$breakpoint-480: 480px;
$breakpoint-480-max: calc(#{$breakpoint-480} - 1px);

// sportsbook redesign breakpoints
$desktop-view-1920: $breakpoint-1920;
$desktop-view-1920-max: $breakpoint-1920-max;
$desktop-view-1600: $breakpoint-1600;
$desktop-view-1600-max: $breakpoint-1600-max;
$desktop-view-1200: $breakpoint-1200;
$desktop-view-1200-max: $breakpoint-1200-max;
$desktop-view-1200-min: $breakpoint-1200;
$desktop-view-1024: $breakpoint-1024;
$desktop-view-1024-max: $breakpoint-1024-max;
$desktop-view-768: $breakpoint-768;
$desktop-view-768-max: $breakpoint-768-max;
$desktop-view-480: $breakpoint-480;
$desktop-view-480-max: $breakpoint-480-max;
$desktop-view-virtuals: 1420px;
