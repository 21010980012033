@use 'abstracts/variables' as vars;

.u-customScrollbar {
  scrollbar-color: vars.$grey-300 transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: vars.$grey-300;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: vars.$grey-300;
    }
  }
}

