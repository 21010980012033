@mixin icon-check() {
  &::before {
    font-family: icons !important;
  }

  @extend .icon-check !optional;
}

@mixin icon-disable() {
  &::before {
    font-family: icons !important;
  }

  @extend .icon-disable !optional;
}
